import React from 'react'

import Layout from '../components/layout'
import PrivacyOne from '../components/strategy/PrivacyOne'


const Work = props => (
  <Layout lang="pt" title="Política de privacidade e proteção de dados" meta={{description: ''}} rootProps={{ className: 'page strategy' }}>
    <PrivacyOne lang="pt" />
  </Layout>
)

export default Work
